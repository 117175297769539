import * as React from "react";
import './App.scss';
import { Routes, Route } from "react-router-dom";



const WebsiteCore = React.lazy(() => import("./website"));
const SuperAdminCore = React.lazy(() => import("./superadmin"));
const OrganizationCore = React.lazy(() => import("./organization"));

function App() {
  return (
    <Routes>
      <Route path="/*"  element={<React.Suspense fallback={<>...</>}><WebsiteCore /></React.Suspense>}  />
      <Route path="org/*"  element={<React.Suspense fallback={<>...</>}><OrganizationCore /></React.Suspense>}  />   
      <Route path="superadmin/*"  element={<React.Suspense fallback={<>...</>}><SuperAdminCore /></React.Suspense>}  />   
  </Routes>
  );
}

export default App;
